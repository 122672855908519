import { Environment } from "@fxp/fxpservices";
import { IWbsResponseMessages } from "../services/types/wbs-details-v2.contract";
import { IDropdownOption } from "@fluentui/react";

export class BulkMaintenance {
  public static HeaderName = "Role maintenance request";
  public static MobileScreenSpacing = "24px";
  public static SmallScreenSpacing = "46px";
  public static LargeScreenSpacing = "96px";
  public static MaxHoursPerWeek = 40;
  public static NoResourceRequest = "There are no resouce requests";
}

export namespace Styles {
  export const MobileScreenSpacing = "24px";
  export const SmallScreenSpacing = "46px";
  export const LargeScreenSpacing = "96px";
}

export class ToolTipMessages {
  public static ChangeScheduleDates = "You may extend or shorten the dates for the project per resource. You may not extend the date over 365 days.";
  public static TotalDemandPlannedHours = "Total Demand hours that have been planned for this resource.";
  public static ConsumedDemandPlannedHour = "Consumed Demand Planned Hours";
  public static CfpVsEac = "The difference between Current Financial Plan and Estimate at Completion";
  public static CfpVsCB = "The difference between Current Financial Plan and Contract Baseline";
  public static BulkForecastingFrontLoad = "This will distribute 40 hours per week from the current week until all planned hours are forecasted.";
  public static BulkForecastingBackLoad = "This will distribute 40 hours per week from the last week of the project until all planned hours are forecasted.";
  public static BulkForecastingEvenLoad = "This will divide remaining hours by number of weeks then apply equally from current week to end of the project.";
  public static BulkForecastingCustomLoad = "This will divide remaining hours by number of weeks then apply equally from current week to end of the project.";
}

export class SuccessMessages {
  public static ExludeResource = "The role has been excluded successfully.";
  public static ChangeScheduleDates =
    "The schedule dates have been changed successfully";
  public static ForecastSaved = "All edits have been saved";
  public static CopyLaborSchedule =
    "The labor schedules have been copied successfully";
  public static PasteLaborSchedule =
    "The labor schedules have been pasted successfully";
  public static CopyExpenseSchedule =
    "The expense schedules have been copied successfully";
  public static PasteExpenseSchedule =
    "The expense schedules have been pasted successfully";
  public static CopySubconFFSchedule =
    "The subcon FF schedules have been copied successfully";
  public static PasteSubconFFSchedule =
    "The subcon FF schedules have been pasted successfully";
  public static CopyUnitSchedule =
    "The unit schedules have been copied successfully";
  public static PasteUnitSchedule =
    "The unit schedules have been pasted successfully";
  public static CopyMaterialSchedule =
    "The material schedules have been copied successfully";
  public static PasteMaterialSchedule =
    "The material schedules have been pasted successfully";
  public static FrontLoadSchedule = "Front loaded successfully";
  public static EvenLoadSchedule = "Even loaded successfully";
  public static BackLoadSchedule = "Back loaded successfully";
  public static CustomLoadSchedule = "Custom loaded successfully";
  public static DeleteNotStaffedResource =
    "Not Staffed resource has been deleted successfully";
  public static ReduceToZero =
    "Forecasted schdules have been reduced to zero successfully";
  public static AppliedGrmCalendarSchedule =
    "Hours from the selected project in GRM Calendar have been applied successfully";
  public static PlanSaved = "All plan edits have been saved. Check the notification above insights to understand when it has reflected in CFP.";
}

export class WarningMessages {
  public static ExcludeAllRoles = "You have removed all the resources.";
  public static MultipleResourceStartDateChange =
    "Start date change will not be applied for resources whose start dates are in the past weeks.";
}

export class ErrorMessages {
  public static SearchNotFound = "No search results found";
  public static ForecastNotSaved = "Unable to Save";
  public static PlanNotSaved = "Plan save has failed due to an error";
  public static RedirectToFCRScreenError = "Unable to redirect to FCR screen due to GET API failure";
  public static StaffedDemandRemoved = "You are trying to remove one or more demands having staffed hours. Please revert those changes to continue.";
  public static NewExpenseRemoved = "Please ensure that a valid cost greater than zero is entered in the CFP column for each newly added expense to continue.";
  public static SubconRemoved = "Please ensure that a valid cost greater than zero is entered in the CFP column to continue.";
  public static NewDemandRemoved = "Please ensure that a valid cost greater than zero is entered in the CFP column for each newly added demand to continue.";
  public static NewMaterialRemoved = "Please ensure that a valid cost greater than zero is entered in the CFP column for each newly added material to continue.";
  public static DemandOverStaffed = "You are trying to remove one or more demands having total staffed hours more than total planned hours. Please revert those changes to continue.";
  public static PlanNotSavedWithExpecption = "Unable to Save Plan Edits due to unforeseen error";
}
/**
 * Pages within the app that can be the source of action.
 * These constants are used for Telemetry.
 */
export namespace SourceConstants {
  /**
   * Source Component Names
   */
  export namespace Component {
    export const Forecast: string = "ForecastComponent";
    export const ForecastingSummaryCard: string = "ForecastingSummaryCardComponent";
    export const ForecastCommandBar: string = "ForecastCommandBarComponent";
    export const PlanCommandBar: string = "PlanCommanBarComponent";
    export const AdditionalFunction: string = "AdditionalFunctionComponent";
    export const CalendarNavigation: string = "CalandarNavigationComponent";
    export const ForecastingSummaryPanel: string = "ForecastingSummaryPanelComponent";
    export const ForecastingSummaryDetailsGridTable: string = "ForecastingSummaryDetailsGridTableComponent";
    export const RouteComponent: string = "RouteComponent";
    export const LaborForecast: string = "LaborForecastComponent";
    export const ForecastDetails: string = "ForecastDetailsComponent";
    export const ForecastGridTable: string = "ForecastGridTableComponent";
    export const ExpenseForecast: string = "ExpenseForecaastComponent";
    export const SubconFFForecast: string = "SubconFFForecastComponent";
    export const ForecastSettingsPanel: string = "ForecastSettingPanelComponent";
    export const BulkForecastPanel: string = "BulkForecastPanelComponent";
    export const ViewResourceDetailsPanel: string = "ViewResourceDetailsPanelComponent";
    export const PjmCustomPanel: string = "PjmCustomPanel";
    export const PjmTooltip: string = "PjmTooltipComponent";
    export const ResourceDetailsGridWrapper: string = "ResourceDetailsGridWrapperComponent";
    export const DebouncedTextField: string = "DebouncedTextField";
    export const PoccRevenueForecast: string = "PoccRevenueForecast";
    export const RatableRevenueForecast: string = "PoccRevenueForecast";
    export const AutoForecastPanel: string = "AutoForecastPanelComponent";
    export const PjmDatePicker: string = "PjmDatePicker"
    export const UnitForecast: string = "UnitForecaastComponent";
    export const MaterialForecast: string = "MaterialForecaastComponent";
    export const SyncEACToPlanPanelComponent: string = "SyncEACToPlanPanelComponent";
    export const ForecastTab: string = "ForecastTabComponent";
    export const LaborPlan: string = 'LaborPlanComponent';
    export const ExpensePlan: string = 'ExpensePlanComponent';
    export const UnitPlan: string = 'UnitPlanComponent';
    export const SubconFFPlan: string = 'SubconFFPlanComponent';
    export const MaterialPlan: string = 'MaterialPlanComponent';
    export const PlanSettingsPanel: string = "ViewSettingsPlanPanelComponent";
    export const PlanGridTable: string = "PlanGridTableComponent";
    export const PlanDetailsComponent: string = "PlanDetailsComponent";
    export const SharedPlanComponent: string = "SharedPlanComponents";
    export const ExportToExcel: string = 'ExportToExcel';
    export const Reports: string = 'Reports';
  }

  export namespace Action {
    export const LaborPlanDetailAction: string = "laborPlanDetailsAction";
    export const PlanSummaryAction: string = "plansummaryActions";
    export const ExpensePlanDetailsActions: string = "expensePlanDetailsActions";
    export const MaterialPlanDetailsActions: string = "materialPlanDetailsActions";
    export const UnitPlanDetailsActions: string = "unitPlanDetailsActions";
    export const EditPlanSaveActions: string = "editPlanSaveActions";
    export const PlanDemandDetailsActions: string = "planDemandDetailsActions";
    export const PlanFlightingAction: string = "planFlightingAction";
    export const PlanAddRoleDetailsAction: string = "PlanAddRoleDetailsAction"
  }

  /**
   * Source Method Names
   */
  export namespace Method {
    export const ExpandCards: string = "ExpandCards";
    export const CollapseCards: string = "CollapseCards";
    export const viewSummaryDetails: string = "ViewSummaryDetails";
    export const ForecastSettings: string = "ForecastSettings";
    export const ExpandView: string = "ExpandView";
    export const OnPreviousNavigation: string = "OnPreviousNavigation";
    export const OnNextNavigation: string = "OnNextNavigation";
    export const CustomButton: string = "CustomButton";
    export const OnViewSummaryDetailsClicked: string = "onViewSummaryDetailsClicked";
    export const CloseForecastingSummaryPanel: string = "closeForecastingSummaryPanel";
    export const OnDropdownChange: string = "onDropdownChange";
    export const ExpandCategory: string = "expandCategory";
    export const CollapseCategory: string = "collapseCategory";
    export const EngagementDetailsErrorUseEffect: string = "EngagementDetailsErrorUseEffect";
    export const OnSaveClicked: string = "onSaveClicked";
    export const GetPlanSyncStatusTexts: string ="getPlanSyncStatusTexts";
    export const OnEmitAutoForecastChanges: string = "onEmitAutoForecastChanges";
    export const GetCommandBarItems: string = "getCommandBarItems";
    export const GetPlanCommandBarItems: string = "getPlanCommandBarItems";
    export const GetPlanCommandBarItemsInEditMode: string = "getPlanCommandBarItemsInEditMode";
    export const ItemsOnEditClick: string = "itemsOnEditClick";
    export const OnDateSelection: string = "onDateSelection";
    export const CollapseView: string = "collapseView";
    export const OnExpandWeeks: string = "onExpandWeeks";
    export const OnCollapseWeeks: string = "onCollapseWeeks";
    export const OnExpandCollapseRow: string = "onExpandCollapseRow";
    export const RenderTooltip: string = "renderTooltip";
    export const RedirectToFCRScreen: string = "redirectToFCRScreen";
    export const OnProjectDropdownChange = "onProjectDropdownChange";
    export const OnProjectTypeDropdownChange = "onProjectTypeDropdownChange";
    export const ToggleCost = "toggleCost";
    export const ToggleRevenue = "toggleRevenue";
    export const ToggleEngagementView = "toggleEngagementView";
    export const ToggleProjectView = "toggleProjectView";
    export const ToggleTaskView = "toggleTaskView";
    export const TogglePreviousWeeksForecast = "togglePreviousWeeksForecast";
    export const TogglePlannedHours = "togglePlannedHours";
    export const OnForecastChoiceGroupChange = "OnForecastChoiceGroupChange";
    export const OnDateViewGroupChange = "onDateViewGroupChange";
    export const OnEmitResourceActionChanges = "onEmitResourceActionChanges";
    export const OnCancelClicked = "onCancelClicked";
    export const OnEditClicked = "onEditClicked";
    export const UpdateAndDispatchSummaryDelta = "updateAndDispatchSummaryDelta"
    export const OnToggleButtonClicked = "onPlanToggle";
    export const OnEditDemandClicked = "onEditDemandClicked";
    export const OnEditMaterialClicked = "onEditMaterialClicked";
    export const OnViewSettingClicked = "onViewSettingClicked";
    export const OnRevertClicked = "onRevertClicked";
    export const ToggleDecoRecoProjects = "toggleDecoRecoProjects";
    export const AssignedPODetails = "AssignedPODetails";
    export const EmitSavedSuccess = "emitSavedSuccess";
    export const SubmitClicked = "submitClicked";
    export const HandleSearchValueChange ="handleSearchValueChange";
    export const HandleAddOrEditDemandChange = "HandleAddOrEditDemandChange";
    export const HandlePlanSaveFailureErrors = "HandlePlanSaveFailureErrors";
    export const HandleBadRequestErrors = "HandleBadRequestErrors";
    export const UpdatePlanDetails = "updatePlanDetails";
    export const HandleExistingDemand = "handleExistingDemand"
    export enum OnPanelClose {
      ViewResourceDetailsPanelComponent = "ViewResourceDetailsPanelComponent",
      ForecastSettingsPanelComponent = "ForecastSettingsPanelComponent",
      BulkForecastPanelComponent = "BulkForecastPanelComponent",
      SyncEACToPlanPanelComponent = 'SyncEACToPlanPanelComponent',
      PlanSettingsPanelComponent = "ViewSettingsPlanPanelComponent",
      ReportsPanelComponent = 'ReportsPanelComponent'
    }
    export const OnDistributeChoiceGroupChange = "onDistributeChoiceGroupChange";
    export enum GetTooltipMessage {
      BulkForecastingFrontLoad = "BulkForecastingFrontLoad",
      BulkForecastingBackLoad = "BulkForecastingBackLoad",
      BulkForecastingEvenLoad = "BulkForecastingEvenLoad",
      BulkForecastingCustomLoad = "BulkForecastingCustomLoad",
      CfpVsEac = "CfpVsEac",
      ChangeScheduleDates = "ChangeScheduleDates"
    }
    export const DismissPanel = "dismissPanel";
    export const ApplyAndDismissPanel = "ApplyAndDismissPanel";
    export const PrepareSearchBoxElement = "prepareSearchBoxElement";
    export const PrepareSearchBoxElementForPlanGrid = "prepareSearchBoxElementForPlanGrid";
    export const DebouncedFunction = "debouncedFunction";
    export const GetTooltipMessageForPoccDenominator = "getTooltipMessageForPoccDenominator";
    export const RatableRevenueHeaderTooltip = "ratableRevenueHeaderTooltip";
    export const GetTooltipMessageForTotalCost = "getTooltipMessageForTotalCost";
    export const GetTooltipMessageForPocc = "getTooltipMessageForPocc";
    export const GetTooltipMessageForPoccCummulativeReview = "getTooltipMessageForPoccCummulativeReview";
    export const GetTooltipMessageForMonthlyReview = "getTooltipMessageForMonthlyReview";
    export const PjmDatePicker = "PjmDatePicker";
    export const SetSuccessMessage = "setSuccessMessage";
    export const SearchResults = "searchResults";
    export const CreateBulkForecast = "viewBulkForecast";
    export const ViewSyncEACHoursToPlanPanel = "viewSyncEACHoursToPlanPanel";
    export const ViewResourceDetails = "viewResourceDetails";
    export const viewGrmCalendarForecastDetails = "viewGrmCalendarForecastDetails";
    export const deleteNotStaffedResource = "deleteNotStaffedResource";
    export const updateToZeroClick = "updateToZeroClick";
    export const pasteResourceSchedules = "pasteResourceSchedules";
    export const copyResourceSchedules = "copyResourceSchedules";
    export const handleTabClick = "handleTabClick";
    export const LogForecastGridTableLoad = "logForecastGridTableLoad";
    export const ValidateToCreateMaintenanceRequest = "validateToCreateMaintenanceRequest";
    export const ExportToExcel: string = 'ExportToExcel';
    export const onReportsDropdownChange: string = 'onReportsDropdownChange';
    export const onPlanChoiceGroupChange = "onPlanChoiceGroupChange";
    export const LogPlanGridTableLoad = "logPlanGridTableLoad";
    export const HandleAddNewExpenseChange = "handleAddNewExpenseChange";
    export const HandleAddNewMaterialChange = "handleAddNewMaterialChange";
    export const LoadLaborPlanDetails = "loadLaborPlanDetails";
    export const LoadSummaryPlanDetails = "loadPlanSummary";
    export const LoadExpensePlanDetails = "loadExpensePlanDetails";
    export const LoadMaterialPlanDetails = "loadMaterialPlanDetails";
    export const LoadUnitPlanDetails = "LoadUnitPlanDetails";
    export const GetAddRoleDetails = "GetAddRoleDetails";
    export const GetLaborAddDemandDetails ="getLaborAddDemandDetails";
    export const LoadPlanFlightingDetails ="loadPlanFligtingDetails";
    export const MaterialPlanDetailsActions = "MaterialPlanDetailsActions";
  }
}

/**
  * Event Constant for logging event name in AI
  */
export namespace LogEventConstants {
  export const ExpandInsights: string = "Expand Insights";
  export const CollapseInsights: string = "Collapse Insights";
  export const SetViewSummaryDetails = "Summary Details";
  export const SetIsForecastSettingsPanelOpen = "Forecast Settings Panel Open";
  export const ExpandView = "Expand View";
  export const CollapseView = "Collapse View";
  export const ProjectCollapseView: string = "Project Collapse View";
  export const ProjectExpandView: string = "Project Collapse View";
  export const MonthExpandView: string = "Month Expand View";
  export const OnPreviousNavigation = "On Previous Navigation";
  export const OnNextNavigation = "On Next Navigation";
  export const ViewSummaryDetails = "View summary details";
  export const CloseForecastingSummaryDetailsPanel = "Close Forecasting Summary Details Panel";
  export const EntitySelectionChangeToViewSummary = "Entity Selection Change To View Summary";
  export const ForecastSummaryDetailsCategoryExpanded = "Forecast Summary Details Category Expanded";
  export const ForecastSummaryDetailsCategoryCollapsed = "Forecast Summary Details Category Collapsed";
  export const AllEditsSaveSuccess = "All Edits Save Success";
  export const SaveForecastClicked: string = "PJM.UI.SaveForecastClicked";
  export const SavePlanClicked: string = "SavePlanButtonClicked";
  export const SaveExpenseClicked: string = "SaveExpenseButtonClicked";
  export const SaveMaterialClicked: string = "SaveMaterialButtonClicked";
  export const SaveForecast = "PJM.UI.SaveForecast";
  export const SavePlanRequestSubmit = "SavePlanRequestSubmit";
  export const PlanSyncStatus = "PlanSyncSAPStatus";
  export const UpdateDemand = "UpdatedDemandToGrid";
  export const AddDemand = "AddDemandToGrid";
  export const AddExpense = "AddNewExpensetoGrid";
  export const AddUnits = "AddNewUnittoGrid";
  export const AddUnitPO = "AddNewUPOtoGrid";
  export const AddMaterial = "AddNewMaterialtoGrid";
  export const ForecastEditCancelClick = "Forecast Edit cancel clicked";
  export const PlanEditCancelClick = "PlanEditCancelButtonClicked";
  export const FrontLoaded = "PJM.UI.BulkForecast.FrontLoaded";
  export const CustomLoaded = "PJM.UI.BulkForecast.CustomLoaded"
  export const BackLoaded = "PJM.UI.BulkForecast.BackLoaded";
  export const EvenLoaded = "PJM.UI.BulkForecast.EvenLoaded";
  export const ViewSettingsClicked = "View Settings Clicked";
  export const ResetToDefaultSettingClicked = "Reset To Default Setting Clicked";
  export const PlanViewSettingsClicked = "Plan View Settings Clicked";
  export const EditPlanClicked = "Edit Plan Clicked";
  export const AddDemandClicked = "AddDemandButtonClicked";
  export const EditDemandClicked = "EditDemandButtonClicked";
  export const EditMaterialClicked = "EditMaterialButtonClicked";
  export const SearchValueChange = "SearchValueChange";
  export const ExpenseCancelClicked = "ExpenseCancelButtonClicked";
  export const MaterialCancelClicked = "MaterialCancelButtonClicked";
  export const AddExpenseClicked = "AddExpenseButtonClicked";
  export const AddMaterialClicked = "AddMaterialButtonClicked";
  export const PlanRevertClicked = "PlanRevertButtonClicked";
  export const ExpenseRevertClicked = "ExpenseRevertButtonClicked";
  export const MaterialRevertClicked = "MaterialRevertButtonClicked";
  export const EditForecastClicked = "Edit Forecast Clicked";
  export const AutoForecastClicked = "Auto Forecast Clicked";
  export const UndoClicked = "Undo Clicked";
  export const PreviousClicked = "Previous Clicked";
  export const NextClicked = "Next Clicked";
  export const DateSelectionClicked = "Date Selection Clicked";
  export const MonthHeaderExpandedToWeeks = "Month Header Expanded To Weeks";
  export const WeeksHeaderCollapsedToMonth = "Weeks Header Collapsed To Month";
  export const EngagementExpanded = "Engagement Expanded";
  export const EngagmentCollapsed = "Engagment Collapsed";
  export const ProjectExpanded = "Project Expanded";
  export const ProjectCollapsed = "Project Collapsed";
  export const TaskExpanded = "Task Expanded";
  export const TaskCollapsed = "Task Collapsed";
  export const ForecastInfoIconHover = "Foecast Info Icon Hover";
  export const OnExpandWeeks = "On Expand Weeks";
  export const OnCollapseWeeks = " On Collapse Weeks";
  export const OnProjectDropdownChange = "Project Dropdown Change";
  export const OnProjectTypeDropdownChange = "Project Type Dropdown Change";
  export const ToggleCost = "Toggle Cost";
  export const ToggleRevenue = "Toggle Revenue";
  export const ToggleEngagementView = "Toggle Engagement View";
  export const ToggleProjectView = "Toggle Project View";
  export const ToggleTaskView = "Toggle Task View";
  export const TogglePlannedHours = "Toggle Planned Hours";
  export const TogglePreviousWeeksForecast = "Toggle Previous Weeks Forecast";
  export const OnForecastChoiceGroupChange = "On Forecast Choice Group Change";
  export const OnDateViewGroupChange = "On Date View Group Change";
  export const OnExpandCollapseRow = "On ExpandCollapse Row";
  export const PreviousMonthCostCompletionExpandView = "Previous Month Cost Completion Expand View";
  export const CurrentsMonthCostCompletionExpandView = "Current Month Cost Completion Expand View"
  export const FutureMonthCostCompletionExpandView = "Future Month Cost Completion Expand View"
  export const PreviousMonthCostCompletionCollapseView = "Previous Month Cost Completion Collapse View";
  export const CurrentsMonthCostCompletionCollapseView = "Current Month Cost Completion Collapse View"
  export const FutureMonthCostCompletionCollapseView = "Future Month Cost Completion Collapse View";
  export const PreviousMonthRevenueCompletionExpandView = "Previous Month Revenue Completion Expand View";
  export const CurrentsMonthRevenueCompletionExpandView = "Current Month Revenue Completion Expand View"
  export const FutureMonthRevenueCompletionExpandView = "Future Month Revenue Completion Expand View"
  export const PreviousMonthRevenueCompletionCollapseView = "Previous Month Revenue Completion Collapse View";
  export const CurrentsMonthRevenueCompletionCollapseView = "Current Month Revenue Completion Collapse View"
  export const FutureMonthRevenueCompletionCollapseView = "Future Month Revenue Completion Collapse View";
  export const ToggleDecoRecoProjects = "Toggle Deco Reco Projects";
  export const AssignedPODetails = "AssignedPODetails";
  export const MaintenanceRequestValidationInitiated = "Maintenance Request Validation Initiated";
  export const MaintenanceRequestValidation = "Maintenance Request Validation";
  export const EditClicked = "PlanEditButtonClicked";
  export const InlineEdits = "UpdateInsights";
  export const ToggleButtonClicked = "PlanToggleButtonClicked";
  export const GetLaborPlanDetailsByWbsId = "GetLaborPlanDetails";
  export const GetPlanSummaryByWbsIdAndBillingType = "GetPlanSummary";
  export const GetExpensePlanDetailsByWbsId = "GetExpensePlanDetails";
  export const GetMaterialPlanDetailsByWbsId = "GetMaterialPlanDetailsByWbsId";
  export const GetUnitPlanDetailsByWbsId = "GetUnitPlanDetails";
  export const GetExpenseDetailsByWbsId = "GetExpenseDetails";
  export const GetNewDemandDetailsByWbsId = "GetDemandDetails";
  export const GetAddRoleDetails = "GetAddRoleDetails";
  export const GetCostRateDetail = "GetCostRateByTaskId";
  export const IsNewPlanEnabled = "IsNewPlanEnabled";

  export enum OnPanelClose {
    ViewResourceDetailsPanelComponent = "ViewResource Details Panel Component Close",
    ForecastSettingsPanelComponent = "Forecast Settings Panel Component Close",
    BulkForecastPanelComponent = "Bulk Forecast Panel Component Component Close",
    SyncEACToPlanPanelComponent = 'Sync EAC To Plan Panel Component Close',
    ReportsPanelComponent = 'Reports Panel Component Close',
    PlanSettingsPanelComponent = "Plan Settings Panel Component Close",
  }
  export const OnDistributeChoiceGroupChange = "On Distribute Choice Group Change";
  export const BulkForecastHover = "Bulk Forecast Hover";
  export const DismissPanel = "Dismiss Panel";
  export const ApplyAndDismissPanel = "Apply And Dismiss Panel";
  export enum GetTooltipMessage {
    BulkForecastingFrontLoad = "Bulk Forecasting Front Load Tool Tip",
    BulkForecastingBackLoad = "Bulk Forecasting Back Load Tool Tip",
    BulkForecastingEvenLoad = "Bulk Forecasting Even Load Tool Tip",
    BulkForecastingCustomLoad = "Bulk Forecasting Custom Load Tool Tip",
    CfpVsEac = "CfpVsEac Tool Tip",
    ChangeScheduleDates = "Change Schedule Dates"
  }
  export const PrepareSearchBoxElement = "Search Box ";
  export const PrepareSearchBoxElementForPlanGrid = "Search Box For Plan Grid";
  export const OnChange = "On Change Edit Forecast";
  export const GetTooltipMessageForPoccDenominator = "Tooltip Message For Pocc Denominator";
  export const GetTooltipMessageForRavenue = "Tooltip Message For Commulate Revenue and Montly Revenue";
  export const GetTooltipMessageForTotalCost = "Tooltip Message For TotalCost";
  export const GetTooltipMessageForPocc = "Tooltip Message For Pocc";
  export const GetTooltipMessageForPoccCummulativeReview = "Tooltip Message For Pocc Cummulative Review";
  export const GetTooltipMessageForMonthlyReview = "Tooltip Message For Monthly Review";
  export const PjmDatePicker = "Pjm Date Picker";
  export const getSearchResults = "search results based on input";
  export const viewBulkForecast = "view bulk forecast panel";
  export const viewSyncEACHoursToPlanPanel = "View Sync EACHours To Plan Panel";
  export const viewGrmCalendarForecastDetails = "View Grm Calendar Forecast Details";
  export const deleteNotStaffedResource = "delete not staffed resource";
  export const viewResourceDetails = "view Resource Details Panel";
  export const updateToZeroClick = "update to zero click";
  export const handleTabClick = "handle tab click";
  export enum SuccessMessage {
    ReduceToZero = "Success Message Forecast Reduce to zero",
    DeleteNotStaffedResource = "Not Staffed resource has been deleted successfully",
    PasteLaborSchedule = "The labor schedules have been pasted successfully",
    CopyLaborSchedule = "The labor schedules have been copied successfully",
    PasteExpenseSchedule = "The Expense schedules have been pasted successfully",
    CopyExpenseSchedule = "The Expense schedules have been copied successfully",
    PasteSubconFFSchedule = "The SubconFF schedules have been pasted successfully",
    CopySubconFFSchedule = "The SubconFF schedules have been copied successfully",
    CopyUnitSchedule = "The Unit schedules have been copied successfully",
    PasteUnitSchedule = "The Unit schedules have been pasted successfully",
    CopyMaterialSchedule = "The Material schedules have been copied successfully",
    PasteMaterialSchedule = "The Material schedules have been pasted successfully",
  }
  export const onPlanChoiceGroupChange = "On Plan Choice Group Change"
  export enum BPE {
    SaveForecast = "PJM.UI.ForecastComponent.SaveForecast",
    SaveButtonClicked = "PJM.UI.ForecastComponent.SaveButtonClicked",
    ForecastGridTableLoad = "PJM.UI.ForecastGridTableComponent.ForecastGridTableLoaded",
    PlanGridTableLoad = "PlanGridTableLoaded",
  }
  export const reportsDropdownChange: string = 'Reports Dropdown Change'
}

export namespace PjmRoutes {
  export const EngagementSummary = {
    displayName: "Summary",
    route: "pjmEntry.engagementDetails.summaryv2"
  };
  export const EngagementFinancials = {
    displayName: "Financials",
    route: "pjmEntry.engagementDetails.financials"
  };
  export const EngagementStaffing = {
    displayName: "Staffing",
    route: "pjmEntry.engagementDetails.staffing"
  };
  export const EngagementWbsDetails = {
    displayName: "Manage EBS",
    route: "pjmEntry.engagementDetails.ebs"
  };
  export const EngagementManageSuppliers = {
    displayName: "Manage Suppliers",
    route: "pjmEntry.engagementDetails.manageSuppliers"
  };
  export const EngagementMilestones = {
    displayName: "Milestones",
    route: "pjmEntry.engagementDetails.milestones"
  };
  export const EngagementActuals = {
    displayName: "Units",
    route: "pjmEntry.engagementDetails.units"
  };
  export const EngagementPlanForecastV1 = {
    displayName: "Plan",
    route: "pjmEntry.engagementDetails.planForecast"
  };
  export const EngagementPlanForecast = {
    displayName: "Forecast",
    route: "pjmEntry.engagementDetails.engagementForecast"
  };
  export const EngagementPlanForecastV2 = {
    displayName: "Plan & Forecast",
    route: "pjmEntry.engagementDetails.engagementForecast"
  };
  export const EngagementNonProcuredMaterials = {
    displayName: "NPM",
    route: "pjmEntry.engagementDetails.nonProcuredMaterials"
  };
  export const EngagementInvoices = {
    displayName: "Customer Invoices",
    route: "pjmEntry.engagementDetails.invoices"
  };

  export const ProjectSummary = {
    displayName: "Summary",
    route: "pjmEntry.projectDetails.summaryv2"
  };

  export const ProjectFinancials = {
    displayName: "Financials",
    route: "pjmEntry.projectDetails.financials"
  };
  export const ProjectStaffing = {
    displayName: "Staffing",
    route: "pjmEntry.projectDetails.staffing"
  };
  export const ProjectWbsDetails = {
    displayName: "Manage EBS",
    route: "pjmEntry.projectDetails.ebs"
  };
  export const ProjectManageSuppliers = {
    displayName: "Manage Suppliers",
    route: "pjmEntry.projectDetails.manageSuppliers"
  };
  export const ProjectMilestones = {
    displayName: "Milestones",
    route: "pjmEntry.projectDetails.milestones"
  };
  export const ProjectActuals = {
    displayName: "Units",
    route: "pjmEntry.projectDetails.units"
  };
  export const ProjectPlanForecastV1 = {
    displayName: "Plan",
    route: "pjmEntry.projectDetails.planForecast"
  };
  export const ProjectPlanForecast = {
    displayName: "Forecast",
    route: "pjmEntry.projectDetails.projectForecast"
  };
  export const ProjectPlanForecastV2 = {
    displayName: "Plan & Forecast",
    route: "pjmEntry.projectDetails.projectForecast"
  };
  export const ProjectNonProcuredMaterials = {
    displayName: "NPM",
    route: "pjmEntry.projectDetails.nonProcuredMaterials"
  };
  export const ProjectInvoices = {
    displayName: "Customer Invoices",
    route: "pjmEntry.projectDetails.invoices"
  };
  export const MyPortfolioHome = {
    displayName: "",
    route: "pjmEntry.portfolio"
  };
  export const EngagementFinancialChangeRequestForm = {
    displayName: "Engagement Financial Change Request Form",
    route: "pjmEntry.engagementDetails.changeRequestForm"
  };
  export const ProjectFinancialChangeRequestForm = {
    displayName: "Project Financial Change Request Form",
    route: "pjmEntry.projectDetails.changeRequestForm"
  }
}

export namespace BulkMaintenance {
  export class CommandBar {
    public static ExcludeRole = "excludeRole";
    public static ChangeScheduleDates = "ChangeScheduleDates";
  }
}

export namespace Staffing {
  export namespace CommandBar {
    export const RoleMaintenance = {
      key: "RoleMaintenance",
      title: "Role Maintenance"
    };
    export const EditSubmit = {
      key: "EditSubmit",
      title: "Edit & Submit"
    };
    export const Copy = {
      key: "Copy",
      title: "Copy"
    };
    export const SplitRole = {
      key: "SplitRole",
      title: "Split role"
    };
    export const Suspend = {
      key: "Suspend",
      title: "Suspend"
    };
    export const Unsuspend = {
      key: "Unsuspend",
      title: "Unsuspend"
    };
    export const Truncate = {
      key: "Truncate",
      title: "Truncate"
    };
    export const Filter = {
      key: "Filter",
      title: "Filter"
    };
    export const Search = {
      key: "Search",
      title: "Search"
    };
  }
}

export namespace Forecast {
  export namespace CommandBar {
    export const ForecastSettings = {
      key: "ForecastSettings",
      title: "View settings"
    };
    export const EditForecast = {
      key: "EditForecast",
      title: "Edit forecast"
    };
    export const AutoForecast = {
      key: "AutoForecast",
      title: "Bulk forecast"
    };
    export const Revert = {
      key: "Revert",
      title: "Revert"
    };
    export const Export = {
      key: "Export",
      title: "Export"
    };
    export const History = {
      key: "History",
      title: "History"
    };
    export const ResetSetting = {
      key: "ResetSettingsToDefault",
      title: "Reset settings to default"
    };
  }

  export namespace Pivot {
    export const Labor = "Labor";
    export const SubconFF = "Subcon FF";
    export const Expenses = "Expenses";
    export const Materials = "Materials";
    export const Units = "Units";
    export const PoccRevenue = "POCC Revenue";
    export const RatableRevenue = "Ratable Revenue";
    export const Reports = "Reports"
  }

  export namespace OverflowMenuItems {
    export namespace SubconFF {
      export const CopyQuantity = {
        key: "CopyQuantity",
        title: "Copy percentages in row"
      };
      export const PasteQuantity = {
        key: "PasteQuantity",
        title: "Paste percentages in row"
      };
      export const BulkForecast = {
        key: "BulkForecast",
        title: "Bulk forecast"
      };
    }

    export const CopyNumbers = {
      key: "CopyNumbers",
      title: "Copy all hours in row",
      subconFFTitle: "Copy all cost percentage in row",
      expenseTitle: "Copy all cost in row",
      unitTitle: "Copy all units in row",
      materialTitle: "Copy all material in row"
    };
    export const PasteRow = {
      key: "PasteRow",
      title: "Paste all hours in row",
      subconFFTitle: "Paste all cost percentage in row",
      expenseTitle: "Paste all cost in row",
      unitTitle: "Paste all units in row",
      materialTitle: "Paste all material in row"
    };
    export const CreateBulkForecast = {
      key: "CreateBulkForecast",
      title: "Create bulk forecast"
    };
    export const ViewResourceDetails = {
      key: "ViewResourceDetails",
      title: "View resource details"
    };
    export const ViewForecastCalendar = {
      key: "ViewForecastCalendar",
      title: "View resource calendar"
    };
    export const DeleteNotStaffed = {
      key: "DeleteNotStaffed",
      title: "Delete a resource"
    };
    export const UpdateRemainingForecast = {
      key: "UpdateRemainingForecast",
      title: "Reduce forecast to zero"
    };
    export const SyncEACHoursToPlan = {
      key: "SyncEACHoursToPlan",
      title: "Sync EAC Hours to Plan"
    };
    export namespace SubconUnits {
      export const AssignedPODetails = {
        key: "AssignedPODetails",
        title: "Assigned PO Details"
      };
      export const UnitDetails = {
        key: "UnitDetails",
        title: "Unit Details"
      };
    }
  }

  export const StaticHeaderNames = {
    EacVsPlanned: "EAC vs.\n Planned",
    Planned: "Planned",
    Actual: "Actual",
    ETC: "ETC",
    EAC: "EAC",
    RemainingEtc: "Remaining \n ETC",
    Assigned: "Assigned",
    Category: "Category"
  };

  export const PlanResourceDetailsGridHeaderNames = {
    Planned: "Planned",
    Category: "Category",
    Staffed: "Staffed"
  };

  export const CostRateReportHeaderNames = {
    CurrentPlannedRole: "Current Planned Role",
    ResourceLocation: "Resource Location",
    StartDate: "Start Date\n(MM/DD/YYYY)",
    StartFiscalWeek: "Start Fiscal Week\n(# week/fiscal year)",
    EndDate: "End Date\n(MM/DD/YYYY)",
    EndFiscalWeek: "End Fiscal Week\n(# week/fiscal year)",
    PlannedLabor: "Planned Labor\n(Hours)",
    PlannedCostRate: "Planned Cost Rate\n",
    PlannedCost: "Planned Cost\n",
  };

  export const BlendedCostRateReportHeaderNames = {
    CurrentResourcedRole: "Current Resource Role",
    ResourceName: "Resource Name",
    ResourceLocation: "Resource Location",
    HistoricalResourceRole: "Historical Resource Role",
    StartDate: "Start Date\n(MM/DD/YYYY)",
    StartFiscalWeek: "Start Fiscal Week\n(# week/fiscal year)",
    EndDate: "End Date\n(MM/DD/YYYY)",
    EndFiscalWeek: "End Fiscal Week\n(# week/fiscal year)",
    Staffed: "Staffed\n(Hours)",
    StaffedCostRate: "Staffed Cost Rate\n",
    StaffedCost: "Staffed Cost\n",
    DemandID: "Demand ID"
  };

  export const PoccStaticHeaderNames = {
    ProjectName: "Project name",
    MonthYear: "Month / Year",
    PoccDenominator: "POCC Denominator",
    LaborCost: "Labor Cost",
    SubconFF: "Subcon FF Cost",
    ExpensesMaterialCosts: "Expense & \n Materials Cost",
    UnitCost: "Units Cost",
    TotalCost: "Total Cost",
    Pocc: "POCC",
    CummulativeReview: "Cumulative \n Revenue",
    MonthlyReview: "Monthly \n Revenue"
  };

  export const RatableStaticHeaderNames = {
    Month: "Month",
    CummulativeRatableReview: "Cumulative Ratable Revenue",
    MonthlyRatableReview: "Monthly Ratable Revenue",
    ProjectName: "Project Name",
  };

  export namespace PoccMonthType {
    export const Past = "Past month";
    export const Current = "Current month";
    export const Future = "Future month";
  }

  export const PoccTooltipMessage = {
    PoccDenominator: "CFP Planned Cost",
    TotalCost: "Total Cost = Labor Cost + Subcon FF Cost + Expense Cost + Units Cost + \n Remaining Risk Reserve in Last Month",
    Pocc: "Percentage of Cost \n Complete = (Total Cost/ \n Pocc Denominator Cost)* \n 100",
    CummulativeReview: "Cummulative Revenue = (Total Cost/ \n Pocc Denominator Cost)*Project \n Revenue",
    MonthlyReview: "Current Monthly Cummulative Revenue - \n Cummulative Revenue Up To Last Month"
  };

  export const TotalMonthsToDisplay = 9;
  export const MinDigitAfterDecimal = 2;
  export const MaxDigitAfterDecimal = 2;
  export const MaxSubconFFFDigitAfterDecimal = 3;
  export const MaxColumnsToDisplay = 9;

  export const MinDigitAfterDecimalForCostRate = 3;
  export const MaxDigitAfterDecimalForCostRate = 3;

  export const CellInputDebounceTimeInMs = 300;

  export const DATE_FORMAT = "YYYY-MM-DD";

  export const NoForecastMessage = "There are no forecastType items available";
  export const ResourceCantBeDeleteMessage =
    "This non staffed resource cannot be removed until the planned hours are at a zero balance.";
  export const ResourceDeletionConfirmationMessage =
    "Resource will be deleted on click of save action from the grid";
  export const SuccessMessageDismissTimeInMilliSecond = 5000;
  export const HighlightTimeIntervalInMilliSecond = 6000;
  export const Current = "Current";
  export const Updated = "Updated";

  export namespace ExpenseType {
    export const Billable = "Billable";
    export const NonBillable = "Non-Billable";
  }

  export namespace MaterialType {
    export const Billable = "Billable";
    export const NonBillable = "Non-Billable";
  }

  export const NotStaffed = "Not Staffed";

  export const POUnassignedLineItem = "PO Unassigned | Line item";

  export const NotAssigned = "Not Assigned";

  export const SavingForecastEditMessage = "Saving all edits";

  export const ApplyingPlanSettings = "Applying Plan Settings";

  export const LoadingResourceCalendar = "Loading resource calendar";

  export const ApplyForecastSettings = "Applying Forecast Settings";

  export const ApplyBulkForecast = "Applying Bulk Forecast";

  export const RevertChanges = "Reverting Changes";

  export const FetchingData = "Fetching Data ...";

  export namespace CalendarViewType {
    export const Weekly = "Weekly";
    export const Monthly = "Monthly";
  }

  export namespace ForecastType {
    export const Overforecasted = "Overforecasted";
    export const Underforecasted = "Underforecasted";
    export const Properforecasted = "Difference";
  }
  export namespace MeasureValue {
    export const Labor = "Hours";
    export const Expense = "Cost";
    export const SubconFF = "%";
    export const Unit = "Quantity";
  }
}

export namespace NoData {
  let cdnBaseUrl: string;
  switch (window["FxpAppSettings"].EnvironmentName) {
    case Environment.Sit:
      cdnBaseUrl = "https://pjmui.azureedge.net/pjmux-react";
      break;
    case Environment.AuroraSit:
      cdnBaseUrl = "https://pjmui.azureedge.net/pjmux-react";
      break;
    case Environment.Uat:
      cdnBaseUrl = "https://pjmuat.azureedge.net/pjmux-react";
      break;
    case Environment.Prod:
      cdnBaseUrl = "https://pjmuiprod.azureedge.net/pjmux-react";
      break;
    default:
      cdnBaseUrl = "https://pjmui.azureedge.net/pjmux-react";
      break;
  }
  export namespace ImageUrl {
    export const SandClockImage = cdnBaseUrl + "/assets/sandClock.jpg";
    export const DesertImage = cdnBaseUrl + "/assets/desert.png";
    export const DogImage = cdnBaseUrl + "/assets/dog-unauthorized.png";
    export const BeachImage = cdnBaseUrl + "/assets/beach.png";
    export const LensImage = cdnBaseUrl + "assets/empty-content.svg";
    export const BinocularWomanImage = cdnBaseUrl + "/assets/binocular-woman.png";
    export const StateReversalIllustration = cdnBaseUrl + "/assets/State-Reversal-Illustration.png";
    export const StateReversalLighteningOne = cdnBaseUrl + "/assets/State-Reversal-Lightening-One.png";
    export const StateReversalLighteningTwo = cdnBaseUrl + "/assets/State-Reversal-Lightening-Two.png";
    export const StateReversalCaution = cdnBaseUrl + "/assets/State-Reversal-Caution.png";
    export const ExclamationMarkImage = cdnBaseUrl + "/assets/Exclamation-mark.png";
    export const DotImage = cdnBaseUrl + "/assets/Dot.png"
  }

  export namespace ImageAlt {
    export const SandClockImage = "Sand clock Image";
    export const DesertImage = "Desert Image";
    export const DogImage = "Dog Image";
    export const BeachImage = "Beach Image";
    export const LensImage = "Lens Image";
    export const BinocularWomanImage = "Binocular Woman Image"
    export const StateReversalIllustration = "State Reversal Illustration Image";
    export const StateReversalLighteningOne = "State Reversal Lightening One Image";
    export const StateReversalLighteningTwo = "State Reversal Lightening Two Image";
    export const StateReversalCaution = "State Reversal Caution Image";
    export const ExclamationMarkImage = "Exclamation Mark Image";
    export const DotImage = "Dot Image"
  }

  export namespace Text {
    export const EngagementNotActive = "This Engagement is not yet Released and Activated.";
    export const EngagementNotActiveNewLine = "After activating engagement in the Financials tab, return here to start forecasting.";
    export const EngagementNotActiveLinkText = "Go to Financials";
    export const UnauthorizeMessageForPlan = "You are not authorized to view the forecast";
    export const NoForecastDataComingFromApi = "Forecast is getting created";
    export const NoForecastDataComingFromApiNewLine = "Please try again in a few minutes";
    export const ForecastDataNotApplicable = " is not applicable for this forecast.";
    export const PlanDataNotApplicable = " plan is not applicable for demand.";
    export const ForecastDataNotAvailable = "No forecast data available.";
    export const RatableRevenueDataNotAvailable = "No Ratable Data available.";
    export const PlanDataNotAvailable = "No plan data available.";
    export const PlanAndForecastDataNotAvailable = "No plan and forecast data available.";
    export const SomethingWentWrong = "Something went wrong";
    export const SomethingWentWrongNewLine = "Unfortunately the data didn't load. Please try again.";
    export const DeCoToReleasedDeleteConfirmation = "Are you sure you want to change status from DeCo to Released?";
    export const ReCoToDeCoDeleteConfirmation = "Are you sure you want to change status from ReCo to DeCo?";
    export const DeCoToReleasedDeleteConfirmationSubText = "This will be a permanent change. To move the status back to DeCo from Released, you will have to complete all the criteria again.";
    export const ReCoToDeCoDeleteConfirmationSubText = "This will be a permanent change. To move the status back to ReCo from DeCo, you will have to complete all the criteria again.";
    export const NoProjectsInReleaseState = "There are no projects in released state for this engagement.";
    export const PrepayTypeProjectText = "Forecasting is not available for this Project type";
  }
}

export class Calendar {
  public static DaysInWeek = 7;
  public static DayFormat = "DD";
  public static YearFormat = "YYYY";
  public static MonthNumberFormat = "M";
}

export class Loader {
  public static Loading = "Loading";
  public static Submit = "Submitting";
  public static EndText = "...";
}

export class CharCodes {
  public static Zero = 48;
  public static Nine = 57;
}

export class AccessibilityConstants {
  public static Enter = "Enter";
  public static TabableTabIndex = 0;
  public static NonTabableTabIndex = -1;
}

export class WeekDay {
  public static Saturday = 6;
}

export class DateType {
  public static StartDate = "StartDate";
  public static EndDate = "EndDate";
}

export class DateFormat {
  public static DDMMMYYYY = "DD-MMM-YYYY";
}

export class Color {
  public static Disabled = "#888888";
}

export class Decimals {
  public static ONEHUNDRED = 100;
}
export namespace CardTitle {
  export namespace Forecast {
    export const Summary = "Forecasting summary";
    export const PlanForecastSummary = "Plan & Forecast Summary";
    export const BurnDown = "Burn down";
    export const ForecastVsActual = "Forecast vs Actuals";
  }
  export namespace Summary {
    export namespace EngagementSummary {
      export const EngagementFinancials = "Engagement financials";
      export const EngagementInformation = "Engagement info";
      export const FincialBreakdown = "Engagement financial breakdown";
      export const Invoices = "Customer invoices";
      export const KeyActions = "Engagement key actions";
      export const ProjectGrid = "Projects within Engagement";
      export const QuickLinks = "Quick engagement links";
      export const Suppliers = "Suppliers";
      export const TeamStructure = "Engagement team structure";
    }
  }
}

export namespace KeyAction {
  export namespace Type {
    export const PendingResources: string = "PendingResources";
    export const PendingExpenseReportsPortfolioContext: string =
      "PendingExpenseReports";
    export const PendingLaborApproval: string = "PendingLaborApproval";
    export const PendingEngagementsForRelease: string =
      "PendingEngagementsForRelease";
    export const RecieptsDue: string = "RecieptsDue";
    export const PendingMilestonesForConfirmation: string =
      "PendingMilestonesForConfirmation";
    export const PendingExpenseReportsBasedOnEntityId: string =
      "PendingExpenseReportsBasedOnEntityId";
    export const PendingLaborBasedOnAssignments: string =
      "PendingLaborBasedOnAssignments";
    export const EngagementPendingForRelease: string =
      "EngagementPendingForRelease";
    export const EngagementStartDateLessContractStartDate: string =
      "EngagementStartDateLessContractStartDate";
    export const ActiveMisalignemdContractualAmendments: string =
      "ActiveMisalignemdContractualAmendments";
  }

  export const HeaderName = "Key actions";
}

export enum AnnouncementType {
  Assertive = "assertive"
}

export enum EntityType {
  Engagement = "Engagement",
  Project = "Project",
  Service = "Service",
  Task = "Task",
  Demand = "Demand",
  Resource = "Resource"
}

export enum FinancialVersion {
  Actuals = "Actuals",
  CurrentFinancial = "CurrentFinancial",
  ContractBaseLine = "ContractBaseLine",
  DeliveryBaseLine = "DeliveryBaseLine",
  EstimationAtComplete = "EstimationAtComplete",
  EstimationToComplete = "EstimationToComplete"
}

export enum ResourceType {
  Labor = "Labor",
  SubconFF = "SubconFF",
  Expense = "Expense",
  Material = "Material",
  Unit = "Unit",
  Pocc = "POCC Revenue",
  RatableRevenue = "Ratable Revenue",
  Reports = "Reports"
}

export enum PayloadResourceType {
  Labor = "Labor",
  Expense = "Expenses",
  Material = "Materials",
  SubconFF = "SubconFF",
  Unit = "Unit",
}

export enum PayloadResourceCode {
  Labor = "0ACT",
  Expense = "0EXP",
  Material = "ZMAT"
}

export enum UnitsResourceType {
  subconUnits = 'ZEXS',
  factoryDeliveredUnits = 'ZUNI'
}

export enum ResourceTypeVersion {
  Labor = 1,
  SubconFF = 2,
  Expense = 3,
  Material = 4,
  Unit = 5
}

export enum PlanResourceTypeVersion {
  Labor = 0,
  SubconFF = 1,
  Expense = 3,
  Material = 4,
  Unit = 2
}

export enum PlanVersion {
  CFP = "CurrentFinancialPlan",
  Forecast = "Forecast"
}

export enum Week {
  Previous = "Previous",
  Current = "Current",
  Forecasting = "Forecasting"
}
export enum BillingType {
  FixedFeeShortName = "FF",
  TimeAndMaterialShortName = "T&M",
  FixedFee = "FixedFee"
}

export enum BillingTypeNumber {
  TimeAndMaterial = 201,
  FixedFee = 202,
  Mixed = 203
}

export enum FinancialMetric {
  Cost = "Cost",
  Revenue = "Revenue",
  PlannedHours = "Planned hours",
  Margin = "Margin",
  CostRate = "Cost rate",
  DecoRecoProjects = "DECO projects",
  Labor = "Labor"
}

export class BulkForecastOptions {
  public static FrontLoad = "frontLoad";
  public static BackLoad = "backLoad";
  public static EvenLoad = "evenLoad";
  public static CustomLoad = "customLoad";
}

export namespace EbsState {
  export const Created: string = "Created";
  export const Released: string = "Released";
  export const TechnicallyCompleted: string = "Technically completed";
  export const Closed: string = "Closed";
  export const TechnicallyCompletedShortName: string = "TECO";
}

export const PstTimezone = "America/Los_Angeles";
export const PstTimezoneFormat = "DD-MMM-YYYY | LT";

/**
 * Response messages to display on the screen (via FXP banners) regarding WBS updates the user has tried to make.
 */
export namespace WBSResponseMessages {
  export const Engagement: IWbsResponseMessages = {
    OnSaveSuccess: "# has been updated",
    OnSavePartialSuccess: "Warning: # has been partially updated",
    OnSaveFailure: "Error on saving # data Please try again."
  };
  export const Project: IWbsResponseMessages = {
    OnSaveSuccess: "Success: # details have been updated",
    OnSavePartialSuccess: "Warning: # has been partially updated",
    OnSaveFailure: "Error on saving # data! Please try again."
  };
  export const Service: IWbsResponseMessages = {
    OnSaveSuccess: "Success: # details have been updated",
    OnSavePartialSuccess: "Warning: # has been partially updated",
    OnSaveFailure: "Error on saving # data Please try again."
  };
  export const Task: IWbsResponseMessages = {
    OnSaveSuccess: "Success: # details have been updated",
    OnSavePartialSuccess: "Warning: # has been partially updated",
    OnSaveFailure: "Error on saving # data! Please try again."
  };
  export const TaskAdded: IWbsResponseMessages = {
    OnSaveSuccess: "# added. Complete setup in Financials view",
    OnSavePartialSuccess: undefined,
    OnSaveFailure: "Error adding new #! Please try again."
  };
}

export namespace ProjectUserStatusTypes {
  export const ECIF: string = "ECIF";
  export const ECIFPartial: string = "ECIFPartial";
  export const JPM: string = "JPM";
  export const VLEA: string = "VLEA";
  export const Prepay: string = "PRP";
  export const DeletedProject: string = "MDL";
}

export const FFRevenueTooltiText = 'FF Revenue Inclusive of Labor, Units, Risk Reserve, Expenses, Materials';

export const CumulativeRatableRevenueTooltipText = "Cumulative Monthly Time-Based Recognition";

export const MontlyRatableRevenueTooltipText = "Time-Based Recognition by Month";

export const ExporttoExcel = "Export to Excel";

export const ExportToExcelSuccessMessage = "Export to Excel will Send to your Registered Email Address"

export namespace Plan {
  export namespace CommandBar {
    export const EditPlan = {
      key: "EditPlan",
      title: "Edit Plan"
    };
    export const PlanSettings = {
      key: "ViewSettings",
      title: "View Settings"
    };
    export const ResetSettings = {
      key: "ResetSettingsToDefault",
      title: "Reset Settings to default"
    };
    export const AddDemand = {
      key: "AddDemand",
      title: "Add Demand"
    };
    export const AddExpense = {
      key: "AddExpense",
      title: "Add Expense"
    };
    export const AddUnit = {
      key: "AddUnit",
      title: "Add Unit"
    };
    export const AddMaterial = {
      key: "AddMaterial",
      title: "Add Material"
    };

    export const AddSubconFF = {
      key: "AddSubconFF",
      title: "Add Subcon FF"
    };
    export const Revert = {
      key: "Revert",
      title: "Revert"
    };
    export const EditExpense = {
      key: "EditExpense",
      title: "Edit Expense"
    };
  }
  export const StaticHeaderNames = {
    Indicator: "Indicator",
    UnitIndicator: "UnitIndicator",
    CB: "CB",
    DB: "DB",
    CFP: "CFP",
    CBCost: "CB Cost",
    DBCost: "DB Cost",
    CFPCost: "CFP Cost",
    CBRevenue: "CB Revenue",
    DBRevenue: "DB Revenue",
    CFPRevenue: "CFP Revenue",
    Staffed: "Staffed",
    Actuals: "Actuals",
    ETC: "ETC",
    EAC: "EAC",
    PlannedCost: "Planned Cost",
    CBPlannedCost: "CB Planned Cost",
    CFPPlannedCost: "CFP Planned Cost",
    PlannedRevenue: "Planned Revenue",
    Supplier: "Supplier"
  }

  export const materialStaticHeaders = [StaticHeaderNames.PlannedCost,
    StaticHeaderNames.PlannedRevenue, StaticHeaderNames.Actuals,
    StaticHeaderNames.ETC, StaticHeaderNames.EAC
  ];
  
  export const UnitStaticHeaders = [StaticHeaderNames.CFP, 
    StaticHeaderNames.Actuals, StaticHeaderNames.ETC, StaticHeaderNames.EAC, 
    StaticHeaderNames.CBCost, StaticHeaderNames.CFPCost,
    StaticHeaderNames.CBRevenue, StaticHeaderNames.CFPRevenue, 
  ];

  export const SubconFFStaticHeaders = [StaticHeaderNames.CBPlannedCost,
    StaticHeaderNames.CFPPlannedCost, StaticHeaderNames.Actuals,
    StaticHeaderNames.ETC, StaticHeaderNames.EAC
  ];

  export const PlanComponent = {
    labor: "LaborPlan",
    expense: "ExpensePlan"
  }
  export namespace IndicatorStatus {
    export const PartialStaffed = "Demanded hours are partially staffed";
    export const OverStaffed = "Demanded hours are exceeded (Overstaffed)";
    export const NotStaffed = "Demanded hours are not staffed";
    export const ProperStaffed = "Demanded hours are staffed";
  };
  export namespace PlanunitsIndicatorStatus {
    export const PartialAssigned = "Demanded units are partially assigned";
    export const FullyAssigned = "Demanded units are fully assigned";
    export const NotAssigned = "Demanded units are not assigned";
  };
  export namespace MetricType {
    export const Cost = "cost";
    export const Revenue = "revenue";
    export const Quantity = "quantity";
  }
  export namespace OverflowMenuItems {
    export const ProjectDetails = {
      key: "ProjectDetails",
      title: "Project Details"
    };
    export const ResourceDetails = {
      key: "ResourceDetails",
      title: "Resource Details"
    };
    export const EditDemand = {
      key: "EditDemand",
      title: "Edit Demand"
    };
    export const EditUnit = {
      key: "EditUnit",
      title: "Edit Unit"
    };
    export const UnitDetails = {
      key: "UnitDetails",
      title: "Unit Details"
    };
    export const EditMaterial = {
      key: "EditMaterial",
      title: "Edit Material"
    };
    export const DemandDetails = {
      key: "DemandDetails",
      title: "Demand Details"
    };
    export const EditPlan = {
      key: "EditPlan",
      title: "Edit Plan"
    };
    export const AssignedPODetails = {
      key: "AssignedPODetails",
      title: "Assigned PO Details"
    };
    export const UnAssignPOLineItem = {
      key: "UnAssignPOLineItem",
      title: "UnAssign PO Line Item"
    };
    export const AssignPOLineItem = {
      key: "AssignPOLineItem",
      title: "Assign PO Line Item"
    };
  }

  export const LoadingFCRForm = "Loading FCR Form";
}

export enum PlanVersionNumber {
  CB = 1,
  DB = 2,
  CFP = 3,
  actuals = 4,
  ETC = 5,
  EAC = 6,
}

export const addExpenseLabels = {
  headerLabel: "Add Expense",
  EBDIDLabel: "EBS ID",
  expenseTypeLabel: "Expense Type",
  plannedCostLabel: "Planned Cost",
  plannedRevenueLabel: "Planned Revenue",
  addButton: "Add",
  closeButton: "Close",
  loadingText: "Loading Add Expense Data"
};

export const addSubconFFLabels = {
  headerLabel: "Add Demand",
  EBDIDLabel: "EBS ID",
  plannedCostLabel: "Planned Cost",
  addButton: "Add",
  cancleButton: "Cancel",
  loadingText: "Loading Add Subcon FF Data ..."
};

export const planAddEditUnitLabels = {
  headerLabel: "Add Unit",
  EBDIDLabel: "EBS ID",
  TotalPlannedUnitsLabel: "Total Planned Units",
  BillingRoleLabel: "Billing Role",
  UnitRoleLabel: "Unit Role",
  addButton: "Add",
  UpdateButton: "Update",
  cancleButton: "Cancel",
  loadingText: "Loading Add unit Data ..."
};

export const addExpenseErrorMessages = {
  EBDID: "Please select EBS ID",
  expenseType: "Please select Expense Type",
  plannedCost: "Please select Planned Cost",
  plannedRevenue: "Please select Planned Revenue"
};

export const addExpenseFields = [
  {
    id: "EBSID",
    lable: addExpenseLabels.EBDIDLabel
  },
  {
    id: "expenseType",
    lable: addExpenseLabels.expenseTypeLabel
  }
];

export const addMaterialLabels = {
  headerLabel: "Add Material",
  editHeaderLabel: "Edit Material",
  EBDIDLabel: "EBS ID",
  materialTypeLabel: "Material Type",
  assetTypeLabel: "Asset Type",
  plannedCostLabel: "Planned Cost",
  plannedRevenueLabel: "Planned Revenue",
  addButton: "Add",
  updateButton: "Update",
  closeButton: "Close",
  loadingText: "Loading Add Material Data"
};

export const addMaterialFields = [
  {
    id: "EBSID",
    lable: addMaterialLabels.EBDIDLabel
  },
  {
    id: "materialType",
    lable: addMaterialLabels.materialTypeLabel
  },
  {
    id: "assetType",
    lable: addMaterialLabels.assetTypeLabel
  }
];

  export const MaterialType = {
    PROCURED: { key: 'procured', text: 'Procured Material' },
    NON_PROCURED: { key: 'non-procured', text: 'Non-Procured Material' }
  };

  export const MaterialAssetType = {
    PROCURED: {
        BILLABLE: { key: 'MAT001', text: 'Procured Billable Material' },
        NON_BILLABLE: { key: 'MAT002', text: 'Procured Non-Billable Material' }
    },
    NON_PROCURED: {
        BILLABLE: {
            PERIPHERALS: { key: 'NPC001', text: 'Non-procured Billable Peripherals' },
            SERVER: { key: 'NPC002', text: 'Non-procured Billable Server' },
            HARDWARE: { key: 'NPC003', text: 'Non-procured Billable Hardware' },
            SOFTWARE: { key: 'NPC004', text: 'Non-procured Billable Software' },
            TRAINING: { key: 'NPC005', text: 'Non-procured Billable Training' },
            SEAT_CHARGE: { key: 'NPC006', text: 'Non-procured Billable Seat Charge' }
        },
        NON_BILLABLE: {
            PERIPHERALS: { key: 'NPC007', text: 'Non-procured Non-billable - Peripherals' },
            SERVER: { key: 'NPC008', text: 'Non-procured Non-billable - Server' },
            HARDWARE: { key: 'NPC009', text: 'Non-procured Non-billable - Hardware' },
            SOFTWARE: { key: 'NPC010', text: 'Non-procured Non-billable - Software' },
            TRAINING: { key: 'NPC011', text: 'Non-procured Non-billable - Training' },
            SEAT_CHARGE: { key: 'NPC012', text: 'Non-procured Non-billable - Seat Charge' }
        }
    }
  };

export const addMaterialErrorMessages = {
  EBDID: "Please select EBS ID",
  expenseType: "Please select Expense Type",
  plannedCost: "Please select Planned Cost",
  plannedRevenue: "Please select Planned Revenue"
};

export const plannedCost = "plannedCost";
export const totalPlannedUnits = "totalPlannedUnits";

export const addSubconFFFields = [
  {
    id: "EBSID",
    lable: addSubconFFLabels.EBDIDLabel
  },
  {
    id: plannedCost,
    lable: addSubconFFLabels.plannedCostLabel
  }
];

export const planAddEditUnitields = [
  {
    id: "EBSID",
    lable: planAddEditUnitLabels.EBDIDLabel
  },
  {
    id: "billingRole",
    lable: planAddEditUnitLabels.BillingRoleLabel
  },
  {
    id: "role",
    lable: planAddEditUnitLabels.UnitRoleLabel
  },
  {
    id: totalPlannedUnits,
    lable: planAddEditUnitLabels.TotalPlannedUnitsLabel
  }
];
export const addDemandLabels = {
  addDemandLabel: "Add Demand",
  editDemandLabel: "Edit Demand",
  EBSIDLabel: "EBS ID",
  billingHoursLabel: "Billing Role",
  totalPlannedHoursLabel: "Total Planned Hours",
  resourceTypeLabel: "Resource Type",
  roleLabel: "Role",
  resourceLocationLabel: "Resource Location",
  addButton: "Add",
  closeButton: "Close",
  loadingText: "Loading Add Demand Data ...",
  loadingEditDemandText: "Loading Edit Demand Data ...",
  costRateLabel: "Cost Rate",
  updateButton: "Update",
  CustomCostRateLabel: "Custom Cost Rate"
};

export const addDemandErrorMessages = {
  EBDID: "Please select EBS ID",
  billingRole: "Please select Billing Role",
  totalPlannedHours: "Please select Total Planned Hours",
  resourceType: "Please select Resource Type",
  role: "Please select Role",
  resourceLocation: "Please select Resource Location",
  costRate: "Please select Cost Rate"
};

export const resourceTypeOptions = [
  {
    text: "FTE Resource",
    key: "0ACT"
  },
  {
    text: "Subcontractor",
    key: "ZEXS"
  }
];

export const resourceTypeCodeToDesc = [
  {
    code: "0ACT",
    desc: "Labor"
  },
  {
    code: "ZEXS",
    desc: "Subcontractor"
  }
];

export const addDemandPanelFields = [
  {
    id: "EBSID",
    lable: addDemandLabels.EBSIDLabel
  },
  {
    id: "billingRole",
    lable: addDemandLabels.billingHoursLabel
  },
  {
    id: "totalPlannedHours",
    lable: addDemandLabels.totalPlannedHoursLabel
  },
  {
    id: "resourceType",
    lable: addDemandLabels.resourceTypeLabel
  },
  {
    id: "role",
    lable: addDemandLabels.roleLabel
  },
  {
    id: "costRate",
    lable: addDemandLabels.costRateLabel
  },
  {
    id: "customCostRate",
    lable: addDemandLabels.CustomCostRateLabel
  },
  {
    id: "resourceLocation",
    lable: addDemandLabels.resourceLocationLabel
  }
];

export const pubSecRoleDetails: IDropdownOption[] = [
  {
    text: "Subcontractor",
    key: "Subcontractor"
  },
  {
    text: "Subcontractor - CL",
    key: "Subcontractor - CL"
  },
  {
    text: "US Delivery Center Subcontractor",
    key: "US Delivery Center Subcontractor"
  }
]

export const PlanPostActions = {
  //Editing of any existing CFP/plan
  EditPlan: "EditPlan",
  //Editing of newly added demand
  EditDemand: "EditDemand",
  //Adding a new demand
  //This is used for adding a new demand. From Front end we are passing the action as AddDemand
  //but before passing to SAP back end we are converting it to AddRole as per the contract agreement
  AddDemand: "AddDemand",
  //Adding a new expense
  AddExpense: "AddExpense",
  EditExpense: "EditExpense",
  EditMaterial: "EditMaterial",
  AddMaterial: "AddMaterial",
  EditSubconFF: "EditSubconFF",
  Editplan: "Editplan",
  AddSubcon: "AddSubcon",
  AddUnit: "AddUnits",
  AssignPO: "AssignPO",
  UnassignPO: "UnassignPO"
}

export const SaveOrLeaveEventActions = {
  TabSwitch: "TabSwitch",
  PlanToggle: "PlanToggle",
  SaveCancel: "SaveCancel",
}

export const CustomCostRateLabel = "customCostRate";
export const TotalPlannedHours = "totalPlannedHours"

export const IsEnableCustomCostRate = "isEnableCustomCostRate"

export const PlanExpenseProjectTypes = {
  FIF: "fif",
  TNM: "tnm",
  SubCon: "subcon",
  NonBill: "non-bill",
  TMC: "tmc",
  REL: 'rel',
  RAR: 'rar'
}

export const SaveOrContinueModalActions = {
  Stay: { key: "Stay", title: "Stay" },
  ContinueWithoutSave: { key: "ContinueWithoutSave", title: "Continue without saving" },
}

export enum ThresholdBreachType {
  TNM = 'TNM',
  FIF = 'FIF'
}

export const PlanThresholdUserActions = {
  TNMSave: 'TNMSave',
  RaiseFCR: 'RaiseFCR',
  ReturnToEdit: 'ReturnToEdit',
}

export namespace HttpConstants {
  export const BAD_REQUEST: number = 400;
  export const UNAUTHORIZED: number = 401;
  export const FORBIDDEN: number = 403;
  export const NOT_FOUND: number = 404;
  export const NOT_ACCEPTABLE: number = 406;
  export const INTERNAL_SERVER_ERROR: number = 500;
}

export const PlanThresholdVarianceTypes = {
  TPH: 'TPH',
  Cost: 'Cost',
  Revenue: 'Revenue'
}

export const SignalREvents = {
  SyncEACHoursToPlan: 'SyncEACToPlanUpdated',
  PlanUpdateInProgress: 'PlanUpdateInProgress',
  AmendmentStatusChange: 'AmendmentStatusChange',
}

export const PlanSaveFailedErrorCategories = {
  EtagMismatch: 'EtagMismatch',
}

export namespace DialogContentProps {
  export const PlanRefreshDialogProps = {
    title: "Plan Updated",
    subText: "Plan details have been updated since you started editing the details. Please refresh the plan to view the latest details.",
  }
}

export enum ExpenseBillableCode {
  Billable = "EXP001",
  NonBillable = "EXP002"
}

export const PlanSyncStatusStates = {
  InProgress: 'InProgress',
  Completed: 'Completed',
  Failed: 'Failed',
}

export const ResourceTypeCodes = {
  FTE: '0ACT',
  Subcontractor: 'ZEXS',
}

export const UserStatusCodes = {
  DECO: 'DECO',
  RECO: 'RECO',
  TECO: 'TECO',
  CLSD: 'CLSD',
  MDL: 'MDL'
}

export const costRateButtonIconinfo = "Click on the Cost Rate Report on how the Cost Rate is Calculated";

export const costRateTextInfo = "This shows the breakdown on how different Cost Rate was applied on the Role/Resource during different period and provides a detailed explanation on how the Cost Rate shown on Resource Details Screen was calculated."

export const costRateReportAPIErrorText = "No Data avilable";

export const generateReportErrorMessages = {
  NoDataFound: "Labor forecast data does not exist for reporting. Please enter the Forecast, Save and try again.",
  errorMessage: "Something went wrong while generating report. Please try again.",

};

export enum OperationStatus {
  Success = "Success",
  Failure = "Failure"
}

export const PlanFlightingMessage: string = "Your Engagement is a part of the new Plan Experience. Use the new Plan & Forecast tab for Planning activities and submitting the forecast on your Engagement.";

export const PlanUnitDetails = {
  PanelHeader: "Unit Details",
  PlannedRole: "Planned Role",
  ResourceType: "Resource Type",
  DemandID: "Demand ID",
  BillingRoleID: "Billing Role ID",
  Category: "Category",
  Planned: "Planned",
  Units: "Units",
  CostRate: "Cost Rate",
  Cost: "Cost",
  BillRate: "Bill Rate",
  Revenue: "Revenue",
  PO: "PO #",
  POLineItem: "PO Line Item #",
  SupplierNumber: "Supplier Number",
  SupplierName: "Supplier Name",
  Assigned: "Assigned"
}

export const UnitTypes = {
  UnitPricedSubcon: "Unit Priced Subcon",
  UnitBasedResource: "Unit Based Resource"
}

export const POAssignment = {
  PanelHeader: "Purchase Order Assignment",
  Task: "Task",
  UnitResource: "Unit Resource",
  DemandQuantity: "Demand Quantity",
  CurrentAssignedQuantity: "Current Assigned Quantity",
  PlannedDemandCostRate: "Planned Demand Cost Rate",
  PurchaseOrder: "Purchase Order",
  PurchaseOrderDetails: "Purchase Order Details",
  POQuantity: "PO Quantity",
  PORemainingQuantity: "PO Remaining Quantity",
  CostRate: "Cost Rate",
}

export const SubconFFRoleKey = "ESSUC";

export const PlanPOUnAssignmentActualExistError = "PO assignments cannot be removed if actuals exist.";
export const SelectedPOCostRateMismatch = "Selected PO Line's Cost rate doesn't match with Demand's planned Cost rate."